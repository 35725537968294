import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Col, getItemWithExpiration } from '../../lib/utils';
import CreateOneSchedule from '../../components/CreateSchedule/CreateOneSchedule/CreateOneSchedule';
import CreateRepeatSchedule from '../../components/CreateSchedule/CreateRepeatSchedule/CreateRepeatSchedule';
import CreateEtcSchedule from '../../components/CreateSchedule/CreateEtcSchedule/CreateEtcSchedule';
import CreateOffSchedule from '../../components/CreateSchedule/CreateOffSchedule/CreateOffSchedule';
import { colors } from '../../lib/colors';
import { useQuery } from '@tanstack/react-query';
import { getCoachListAPI } from '../../api/schedule';
import _ from 'lodash';

type PageType = 'oneSchedule' | 'repeatSchedule' | 'etcSchedule' | 'offSchedule';

const CreateSchedulePage = ({ createAlert, setIsLoading }: any) => {
    const [authorization, setAuthorization] = useState(getItemWithExpiration('authorization'));
    const [authObject, setAuthObject] = useState(getItemWithExpiration('authObject'));
    const location = useLocation();

    const pageType: PageType = location.state.type;
    const selectedSchedule: any = location.state?.selectedSchedule;
    const calendarType = location.state.calendarType;
    const calendarSelectedDay = location.state.calendarSelectedDay;
    const calendarScrollY = location.state.calendarScrollY;

    const [coachOption, setCoachOption] = useState<any>([]);

    //  강사 리스트 조회 API : (GET)
    const getCoachList = useQuery(
        ['getCoachListAPI'],
        async () => await getCoachListAPI(authObject?.centerId, authorization),
        {
            onSuccess: (res) => {
                if (res.status === 200) {
                    const response = res?.data?.coachNameList;
                    const coachList = [];

                    if (response && response.length > 0) {
                        for (let i = 0; i < response.length; i++) {
                            coachList.push({ value: response[i].coachId, label: response[i].name });
                        }
                    }

                    setCoachOption(coachList);
                }
            },
            onError: (err) => {
                console.log(err);
            },
            enabled: !!authorization && !!!authObject?.testAccount,
            // refetchOnWindowFocus: 'always',
            staleTime: 0,
        }
    );

    return (
        <Col style={{ width: '100%', alignItems: 'center', marginTop: 64, marginBottom: 218 }}>
            <Col
                style={{
                    width: 1192,
                    minHeight: 400,
                    borderRadius: 16,
                    backgroundColor: colors.WHITE_50,
                    border: '1px solid #C3D4F4',
                    marginTop: 64,
                }}
            >
                {pageType === 'oneSchedule' && (
                    <CreateOneSchedule
                        coachOption={coachOption}
                        authorization={authorization}
                        authObject={authObject}
                        createAlert={createAlert}
                        selectedSchedule={selectedSchedule}
                        calendarType={calendarType}
                        calendarSelectedDay={calendarSelectedDay}
                        calendarScrollY={calendarScrollY}
                    />
                )}
                {pageType === 'repeatSchedule' && (
                    <CreateRepeatSchedule
                        coachOption={coachOption}
                        authorization={authorization}
                        authObject={authObject}
                        createAlert={createAlert}
                        selectedSchedule={selectedSchedule}
                        calendarType={calendarType}
                        calendarSelectedDay={calendarSelectedDay}
                        calendarScrollY={calendarScrollY}
                    />
                )}
                {pageType === 'etcSchedule' && (
                    <CreateEtcSchedule
                        coachOption={coachOption}
                        authorization={authorization}
                        authObject={authObject}
                        createAlert={createAlert}
                        selectedSchedule={selectedSchedule}
                        calendarType={calendarType}
                        calendarSelectedDay={calendarSelectedDay}
                        calendarScrollY={calendarScrollY}
                    />
                )}
                {pageType === 'offSchedule' && (
                    <CreateOffSchedule
                        coachOption={coachOption}
                        authorization={authorization}
                        authObject={authObject}
                        createAlert={createAlert}
                        selectedSchedule={selectedSchedule}
                        calendarType={calendarType}
                        calendarSelectedDay={calendarSelectedDay}
                        calendarScrollY={calendarScrollY}
                    />
                )}
            </Col>
        </Col>
    );
};

export default CreateSchedulePage;
