import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createRepeatScheduleAPI, getMemberListForCenterAPI } from '../../../api/schedule';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Col, isSameDate, Row } from '../../../lib/utils';
import { Body3Regular, Subtitle1Bold } from '../../../lib/font';
import { colors } from '../../../lib/colors';
import WhiteSquareButton from '../../Button/WhiteSquareButton';
import ColorSquareButton from '../../Button/ColorSquareButton';
import CreateRepeatScheduleLeft from './CreateRepeatScheduleLeft';
import CreateRepeatScheduleRight from './CreateRepeatScheduleRight';
import ModalMembershipCreate from '../../MemberProfile/ModalMembershipCreate';
import ModalRegisterPass from '../../../scenes/ClassPass/ModalRegisterPass/ModalRegisterPass';

import Calendar from '../../Calendar/Calendar';

interface CalculatedSchedule {
    startTime: Dayjs;
    endTime: Dayjs;
}

interface ScheduleDate {
    startTime: Dayjs;
    endTime: Dayjs;
}

const CreateRepeatSchedule = ({
    coachOption,
    authorization,
    authObject,
    createAlert,
    selectedSchedule,
    calendarType,
    calendarSelectedDay,
    calendarScrollY,
}: any) => {
    const navigate = useNavigate();
    const currentTime = dayjs(selectedSchedule?.scheduleStartTime);
    const roundedTime = currentTime.minute(Math.floor(currentTime.minute() / 10) * 10).second(0);
    const currentTimeAdd50 = dayjs(roundedTime).add(50, 'minute');

    /**
     * coach : 담당강사
     * selectedDay: 첫등록일
     * startTime : 시작 시간
     * endTime : 종료시간
     * oneOnOneMemberShipIndex : 멤버쉽의 현재 index
     * maxRepeatNumber : 반복 횟수중 최대회수
     * repeatScheduleList : 정제된 반복 스케줄 리스트
     */
    const [coach, setCoach] = useState({
        value: selectedSchedule ? selectedSchedule?.coachId : '',
        label: selectedSchedule ? selectedSchedule?.coachName : '강사를 선택해주세요!',
    });
    const [selectedDay, setSelectedDay] = useState<any>(
        selectedSchedule ? new Date(selectedSchedule?.scheduleStartTime) : new Date()
    );
    const [repeatNumber, setRepeatNumber] = useState<string>('');
    const [startTime, setStartTime] = useState({ value: roundedTime, label: dayjs(roundedTime).format('A hh:mm') });
    const [endTime, setEndTime] = useState({
        value: currentTimeAdd50,
        label: dayjs(currentTimeAdd50).format('A hh:mm'),
    });
    const [currentMemberShipIndex, setCurrentMemberShipIndex] = useState<number>(1);
    const [maxRepeatNumber, setMaxRepeatNumber] = useState<number>(0);
    const [repeatScheduleList, setRepeatScheduleList] = useState<any>([]);

    // 반복요일
    const dayNames = ['일', '월', '화', '수', '목', '금', '토'];
    const initializeDays = () => {
        return dayNames.map((day, index) => ({
            week: index.toString(),
            value: day,
            isSelected: false, // 선택 되엇던 요일
            startTime: { value: roundedTime, label: dayjs(roundedTime).format('A hh:mm') },
            endTime: {
                value: currentTimeAdd50,
                label: dayjs(currentTimeAdd50).format('A hh:mm'),
            },
        }));
    };
    const [daysArr, setDaysArr] = useState(initializeDays);
    const [isEveryDaySameTime, setIsEveryDaySameTime] = useState<boolean>(true);

    /**
     * searchText: 회원 검색
     * memberList: 강사의 연결된 모든 회원의 리스트
     * filteredMemberList : 검색결과가 반영된 회원 리스트
     * selectedMember: 선택된 회원
     */
    const [searchText, setSearchText] = useState<string>('');
    const [memberList, setMemberList] = useState<any>([]);
    const [filteredMemberList, setFilteredMemberList] = useState<any>([]);
    const [selectedMember, setSelectedMember] = useState<any>([]);

    const [isCalendar, setIsCalendar] = useState<boolean>(false); // 달력 boolean
    const [timeList, setTimeList] = useState<any>(null); // 10분단위 시간 배열

    // 수강권 등록 모달 열기
    const [isModalMembershipCreateVisible, setIsModalMembershipCreateVisible] = useState(false);

    // 회원 수강권 새로 생성 모달
    const [isRegisterPassModal, setIsRegisterPassModal] = useState(false);

    const [selectedMemberName, setSelectedMemberName] = useState('');
    const [selectedMemberId, setSelectedMemberId] = useState('');

    // 회원 검색 이벤트
    const onChangeText = (e: any) => {
        setSearchText(e.target.value);
    };

    // 담당 강사 선택
    const handleCoachSelect = (option: any) => {
        setCoach(option);
    };

    // 시작 시간 선택
    const handleStartTimeSelect = (option: any) => {
        setStartTime(option);
    };

    // 종료 시간 선택
    const handleEndTimeSelect = (option: any) => {
        setEndTime(option);
    };

    // 멤버 선택
    const handleMemberSelect = (option: any) => {
        setSearchText('');
        let cloneSelectedMember = _.cloneDeep(selectedMember);
        cloneSelectedMember.push(option);
        setSelectedMember(cloneSelectedMember);
    };

    // 선택된 회원 지우는 함수
    const onClickDeleteSelectedMember = (value: string) => {
        const filterSelectedMemberList = selectedMember.filter((e: any) => e.value !== value);
        setSelectedMember(filterSelectedMemberList);
        setMaxRepeatNumber(0);
    };

    // 반복횟수 -> 최대횟수 클릭이벤트
    const onChangeRepeatCount = (repeatNumberString: string) => {
        let repeatNumber = parseInt(repeatNumberString.replace(/[^0-9]/g, ''));

        if (repeatNumber < 1) {
            repeatNumber = 1;
        }
        if (repeatNumber > maxRepeatNumber) {
            repeatNumber = maxRepeatNumber;
        }

        setRepeatNumber(repeatNumber.toString());
    };

    // 반복횟수 -> 최대횟수 클릭이벤트
    const onClickMaxRepeatCount = () => {
        if (maxRepeatNumber) {
            setRepeatNumber(maxRepeatNumber.toString());
        }
    };

    // 반복요일 클릭이벤트
    const onClickRepeatDay = (item: any) => {
        let cloneDayjsArr = _.cloneDeep(daysArr);
        let findIndex: any = cloneDayjsArr.findIndex((e: any) => e.value === item.value);

        cloneDayjsArr[findIndex].isSelected = !cloneDayjsArr[findIndex].isSelected;

        // 요일이 해제가 되면 해당요일에 시간을 초기화해준다
        for (let i = 0; i < cloneDayjsArr.length; i++) {
            if (cloneDayjsArr[i]?.isSelected === false) {
                cloneDayjsArr[i].startTime = { value: roundedTime, label: dayjs(roundedTime).format('A hh:mm') };
                cloneDayjsArr[i].endTime = {
                    value: currentTimeAdd50,
                    label: dayjs(currentTimeAdd50).format('A hh:mm'),
                };
            }
        }

        setDaysArr(cloneDayjsArr);
    };

    // 수강권 추가하기 버튼 클릭이벤트
    const onClickClassPassPage = (selectedMember: any) => {
        setSelectedMemberId(selectedMember.value);
        setSelectedMemberName(selectedMember.label);
        setIsModalMembershipCreateVisible(true);
    };

    //  회원 리스트 조회 API : (GET)
    const getMemberListForCenter = useQuery(
        ['getMemberListForCenterAPI', coach?.value],
        async () => await getMemberListForCenterAPI(authObject?.centerId, coach?.value, authorization),
        {
            onSuccess: (res) => {
                if (res.status === 200) {
                    const response = res?.data?.memberDataList;
                    let memberListToRes: any = [];

                    if (response.length > 0) {
                        for (let i = 0; i < response.length; i++) {
                            memberListToRes.push({
                                value: response[i]?.memberId,
                                label: response[i]?.alias,
                                status: response[i]?.membershipStatus,

                                memberships: response[i]?.memberships ? response[i]?.memberships : [],
                                profileColorType: response[i]?.profileColorType,
                                profileImageUrl: response[i]?.profileImageUrl,
                                profileName: response[i]?.profileName,
                            });
                        }
                        setMemberList(memberListToRes);
                        setFilteredMemberList(memberListToRes);

                        // 기존 선택된 회원의 수강권을 변경된 수강권들로 업데이트 하는 로직
                        const _selectMember = _.cloneDeep(selectedMember);

                        if (_selectMember.length === 1 || _selectMember.length === 2) {
                            for (const member of _selectMember) {
                                const memberId = member.value;
                                const resultMember = memberListToRes.find(
                                    (memberRes: any) => memberRes.value === memberId
                                );
                                if (resultMember) {
                                    member.memberships = resultMember.memberships;
                                }
                            }
                            setSelectedMember(_selectMember);
                        }
                        // selectedMember가 0명이거나 3명 이상인 경우
                        else {
                            setSelectedMember([]);
                        }
                    } else {
                        setMemberList([]);
                        setSelectedMember([]);
                    }
                }
            },
            onError: (err) => {
                console.log(err);
            },
            enabled: !!authorization && !!coach?.value,
            // refetchOnWindowFocus: 'always',
            staleTime: 0,
        }
    );

    //반복 수업 생성 API : (POST)
    const createRepeatSchedule = useMutation({
        mutationFn: async () =>
            await createRepeatScheduleAPI(
                authObject?.centerId,
                dayjs(selectedDay).toISOString(),
                coach?.value,
                selectedMember[0]?.value,
                selectedMember[0]?.memberships[currentMemberShipIndex - 1]?.id,
                repeatScheduleList,
                authorization
            ),
        onSuccess: (res) => {
            if (res.status === 200) {
                const startTime = dayjs(repeatScheduleList[0].startTime).toISOString();
                navigate('/schedule', {
                    state: { calendarType, calendarTimeInfo: startTime },
                    replace: true,
                });
            } else if (res.status === 410 || res.status === 420 || res.status === 430) {
                createAlert(
                    '중복',
                    `선택된 담당 강사 ${coach?.label}님이\n해당시간에 중복된 스케줄이 존재합니다`,
                    true
                );
            } else {
                createAlert('오류', `서버 내부 오류\n 지속적인 오류발생시 고객센터로 문의바랍니다`, true);
            }
        },
    });

    // 반복 수업 등록 버튼 클릭이벤트
    const onClickRegisterRepeatScheduleButton = () => {
        if (coach.value === '') {
            createAlert('필수 입력', '담당 강사를 선택해주세요', true);
            return;
        }

        if (repeatNumber === '') {
            createAlert('필수 입력', '반복 횟수를 입력해주세요', true);
            return;
        }

        const findSelectedIndex = daysArr.findIndex((item: any) => item.isSelected === true);

        if (findSelectedIndex === -1) {
            createAlert('필수 입력', '반복 요일을 선택해주세요', true);
            return;
        }

        let findNullMembership = selectedMember.findIndex((item: any) => item?.memberships.length === 0);

        if (findNullMembership !== -1) {
            createAlert(
                '필수 입력',
                '수강권이 없는 회원이 존재합니다\n반드시 수강권을 만들고 일정을 만들어주세요',
                true
            );
        }

        let isUnavailableTime = false;
        repeatScheduleList.map((repeatSchedule: any) => {
            if (
                dayjs(repeatSchedule.startTime).isAfter(repeatSchedule.endTime) ||
                dayjs(repeatSchedule.startTime).isSame(repeatSchedule.endTime)
            ) {
                isUnavailableTime = true;
            }
        });
        if (isUnavailableTime) {
            createAlert(
                '입력 오류',
                '시작 시간은 종료 시간과 같거나 늦을 수 없습니다\n다시 한번 확인해주세요!',
                '확인',
                () => {}
            );
            return;
        }

        createRepeatSchedule.mutate();
    };

    //스케줄 리스트 계산
    const calculateScheduleDates = (
        startStandardDate: any, // 시작일자
        weeklySchedules: any, // 요일리스트
        countOfSchedules: any // 횟수
    ) => {
        // 그주의 월요일
        const firstWeekStandardDate = dayjs(startStandardDate).clone().startOf('week');
        weeklySchedules.sort(function (a: any, b: any) {
            return parseInt(a.week) - parseInt(b.week);
        });
        const calculatedWeeklySchedules = weeklySchedules.map((schedule: any) => {
            const startHour = parseInt(dayjs(schedule.startTime.value).format('HH'));
            const startMinute = parseInt(dayjs(schedule.startTime.value).format('mm'));
            const endHour = parseInt(dayjs(schedule.endTime.value).format('HH'));
            const endMinute = parseInt(dayjs(schedule.endTime.value).format('mm'));
            const offsetStartMinute = schedule.week * 24 * 60 + startHour * 60 + startMinute;
            const offsetEndMinute = schedule.week * 24 * 60 + endHour * 60 + endMinute;
            const startTime = firstWeekStandardDate.clone().add(offsetStartMinute, 'minutes');
            const endTime = firstWeekStandardDate.clone().add(offsetEndMinute, 'minutes');
            return { startTime, endTime };
        });
        const scheduleDates: any = [];
        let weekIndex = 0;
        while (scheduleDates.length < countOfSchedules) {
            calculatedWeeklySchedules.map((schedule: any) => {
                // 결과배열에 저장된 스케쥴의 갯수가 사용자가 입력한 횟수 이상이 될때 continue;
                if (scheduleDates.length >= countOfSchedules) return;
                const startTime = schedule.startTime.clone().add(weekIndex, 'weeks');
                // 계산된 startTime이 시작 기준 시간보다 이전이라면 continue;
                if (startTime.isBefore(startStandardDate)) return;
                const endTime = schedule.endTime.clone().add(weekIndex, 'weeks');
                scheduleDates.push({ startTime, endTime });
            });
            weekIndex++;
        }

        return scheduleDates;
    };

    // 반복 수업 스케줄 리스트 정제
    useEffect(() => {
        if (daysArr) {
            const filterList = daysArr.filter((item: any) => item.isSelected === true);

            if (repeatNumber !== '') {
                if (filterList.length > 0) {
                    const scheduleList = calculateScheduleDates(
                        dayjs(selectedDay).format('YYYY-MM-DD'),
                        filterList,
                        parseInt(repeatNumber)
                    );
                    if (scheduleList) {
                        setRepeatScheduleList(scheduleList);
                    }
                }
            }
        }
    }, [daysArr, repeatNumber]);

    // 수강권의 최대회수 탐색
    useEffect(() => {
        if (selectedMember) {
            if (selectedMember[0]?.memberships) {
                const currentMemberShip = selectedMember[0]?.memberships[currentMemberShipIndex - 1];
                setMaxRepeatNumber(currentMemberShip?.remainSession);
            }
        }
    }, [selectedMember]);

    // 시작 시간이 변할때 endTime정제
    useEffect(() => {
        if (startTime) {
            const add50Min: Dayjs = dayjs(startTime.value).add(50, 'minute');

            if (
                add50Min.isAfter(dayjs(startTime.value).endOf('day')) ||
                isSameDate(add50Min, dayjs(startTime.value).endOf('day'))
            ) {
                setEndTime({
                    value: dayjs().endOf('day').add(1, 'millisecond'),
                    label: dayjs().endOf('day').add(1, 'millisecond').format('A hh:mm'),
                });
            } else {
                setEndTime({ value: dayjs(add50Min), label: dayjs(add50Min).format('A hh:mm') });
            }
        }
    }, [startTime]);

    // 회원이름 검색 정제
    useEffect(() => {
        if (memberList) {
            if (searchText !== '') {
                let filterMemberList = memberList.filter((e: any) => {
                    return (
                        e.label.includes(searchText) &&
                        !selectedMember.some((selected: any) => selected.value === e.value)
                    );
                });

                setFilteredMemberList(filterMemberList);
            } else {
                let filterMemberList = memberList.filter((e: any) => {
                    return !selectedMember.some((selected: any) => selected.value === e.value);
                });

                setFilteredMemberList(filterMemberList);
            }
        }
    }, [searchText, selectedMember]);

    // 시간 10분단위로 생성
    useEffect(() => {
        // 시간생성
        const generateTimeSlots = () => {
            const timeSlots = [];
            const start = dayjs().startOf('day').hour(0).minute(0);
            for (let i = 0; i < 144; i++) {
                // 24 hours * 6 (10분 간격)
                const time = start.add(i * 10, 'minute');
                const formattedTime = time.format('A hh:mm'); // 오전/오후 표시
                timeSlots.push({ value: time, label: formattedTime });
            }
            return timeSlots;
        };

        const timeSlots = generateTimeSlots();
        setTimeList(timeSlots);
    }, []);

    return (
        <Col style={{ paddingLeft: 101, paddingTop: 56, paddingRight: 101, paddingBottom: 64 }}>
            <Row style={{ alignItems: 'center', justifyContent: 'space-between' }}>
                <Row style={{ alignItems: 'flex-end' }}>
                    <Subtitle1Bold style={{ color: colors.LAVEL_4 }}>{'반복수업 등록하기'}</Subtitle1Bold>
                    <Body3Regular style={{ color: colors.LAVEL_1, marginBottom: 3, marginLeft: 16 }}>
                        {'매주 일정이 고정적인 회원 전용이에요!'}
                    </Body3Regular>
                </Row>

                <Row style={{ alignItems: 'center', width: 252, justifyContent: 'space-between' }}>
                    <WhiteSquareButton
                        customStyle={{ width: 100, height: 36 }}
                        text="돌아가기"
                        size="small"
                        callBack={() => {
                            navigate('/schedule', {
                                state: { calendarType, calendarSelectedDay, calendarScrollY },
                                replace: true,
                            });
                        }}
                    />
                    <ColorSquareButton
                        isGradient={true}
                        customStyle={{ width: 136, height: 36 }}
                        text="반복일정 등록"
                        size="small"
                        callBack={onClickRegisterRepeatScheduleButton}
                    />
                </Row>
            </Row>

            <Row style={{ marginTop: 56 }}>
                <CreateRepeatScheduleLeft
                    coachOption={coachOption}
                    coach={coach}
                    handleCoachSelect={handleCoachSelect}
                    selectedDay={selectedDay}
                    setIsCalendar={setIsCalendar}
                    timeList={timeList}
                    startTime={startTime}
                    handleStartTimeSelect={handleStartTimeSelect}
                    endTime={endTime}
                    handleEndTimeSelect={handleEndTimeSelect}
                    repeatNumber={repeatNumber}
                    onChangeRepeatCount={onChangeRepeatCount}
                    daysArr={daysArr}
                    setDaysArr={setDaysArr}
                    onClickRepeatDay={onClickRepeatDay}
                    isEveryDaySameTime={isEveryDaySameTime}
                    setIsEveryDaySameTime={setIsEveryDaySameTime}
                    roundedTime={roundedTime}
                    currentTimeAdd50={currentTimeAdd50}
                    onClickMaxRepeatCount={onClickMaxRepeatCount}
                    maxRepeatNumber={maxRepeatNumber}
                />
                <CreateRepeatScheduleRight
                    selectedMember={selectedMember}
                    filteredMemberList={filteredMemberList}
                    handleMemberSelect={handleMemberSelect}
                    searchText={searchText}
                    onChangeText={onChangeText}
                    onClickDeleteSelectedMember={onClickDeleteSelectedMember}
                    onClickClassPassPage={onClickClassPassPage}
                    coach={coach}
                    currentMemberShipIndex={currentMemberShipIndex}
                    setCurrentMemberShipIndex={setCurrentMemberShipIndex}
                />
            </Row>

            {isCalendar && (
                <Calendar
                    selectedDay={selectedDay}
                    setSelectedDay={setSelectedDay}
                    setIsCalendar={setIsCalendar}
                    isPrevMonth={true}
                    isNextMonth={true}
                    style={{
                        width: 420,
                        height: 370,
                        borderRadius: 24,
                        backgroundColor: colors.WHITE_50,
                        boxShadow: '1px 1px 8px 0px #2B529D2E',
                        position: 'absolute',
                        zIndex: 1999,
                        marginTop: 340,
                    }}
                />
            )}

            {isModalMembershipCreateVisible && (
                <ModalMembershipCreate
                    setIsVisible={setIsModalMembershipCreateVisible}
                    onClickCreateNewMembership={() => setIsRegisterPassModal(true)}
                    memberName={selectedMemberName}
                    coachName={coach?.label}
                    coachId={coach?.value}
                    memberId={selectedMemberId}
                    createAlert={createAlert}
                    type={'schedule'}
                />
            )}

            {isRegisterPassModal && (
                <ModalRegisterPass
                    modalVisible={isRegisterPassModal}
                    setModalVisible={setIsRegisterPassModal}
                    createAlert={createAlert}
                />
            )}
        </Col>
    );
};

export default CreateRepeatSchedule;
